
const digest = '5ff11147d0364cec60c5c345ec543be97e67baf94091f89570b5e0dadbac2f11';
const css = `/* Expandable List and Expandable Items */

._list_1atms_3 {
  position: relative;
  width: 100%;
  background-color: var(--color-white);
  border: 1px solid var(--color--neutral-20);
  border-radius: 0.25em;
}

._item_1atms_11:first-child ._header_1atms_11 {
  border-top: none;
}

._item_1atms_11._isExpanded_1atms_15 {
  background-color: var(--color-white);
  padding-bottom: 1.5em;
}

._header_1atms_11 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5em;
  transition: var(--background-color) 200ms ease-in;
  border-top: 1px solid var(--color--neutral-20);
}

._header_1atms_11:hover {
  background-color: var(--color-legacy-gray-1);
  transition: background-color 200ms ease-in;
  cursor: pointer;
}

._item_1atms_11._isExpanded_1atms_15 ._header_1atms_11:hover {
  background-color: var(--color-white);
}

._title_1atms_39 {
  font-size: 1rem;
  line-height: 1;
}

._summary_1atms_44 {
  margin-left: auto;
}

._toggle_1atms_48 {
  position: relative;
  display: block;
  width: 0.75rem;
  height: 0.5rem;
  margin-left: 2.625rem;
  transition: transform 300ms var(--transition);
}

._item_1atms_11._isExpanded_1atms_15 ._toggle_1atms_48 {
  transform: rotate(180deg);
}

._toggle_1atms_48:before,
._toggle_1atms_48:after {
  content: "";
  position: absolute;
  width: 0.125em;
  height: 0.5em;
  top: 0;
  border-radius: 0.125em;
  background-color: var(--color--neutral-10);
}

._toggle_1atms_48:before {
  left: 0.1875em;
  transform: rotate(-45deg);
}

._toggle_1atms_48:after {
  left: 0.4375em;
  transform: rotate(45deg);
}

._content_1atms_82 {
  display: none;
  margin: 0 1.5em;
  border: 1px solid var(--color--neutral-20);
  border-radius: 0.25em;
  background-color: var(--color-white);
  overflow-x: scroll;
}

._item_1atms_11._isExpanded_1atms_15 ._content_1atms_82 {
  display: block;
}

._summaryGrid_1atms_95 {
  display: inline-grid;
  grid-auto-columns: 9rem;
  grid-auto-flow: column;
  grid-gap: 0.25rem;
  text-align: right;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"list":"_list_1atms_3","item":"_item_1atms_11","header":"_header_1atms_11","isExpanded":"_isExpanded_1atms_15","title":"_title_1atms_39","summary":"_summary_1atms_44","toggle":"_toggle_1atms_48","content":"_content_1atms_82","summaryGrid":"_summaryGrid_1atms_95"};
export { css, digest };
  